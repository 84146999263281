<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Form Permintaan Material</strong>
        <a-button-group class="ml-2">
        <a-button class="btn btn-outline-primary" @click="handleSubmit" :disabled="rowData.length === 0"
          >Simpan</a-button
        >
        <a-button v-if="action === 'update'" class="btn btn-outline-danger" @click="resetForm(true)"
          >Cancel Update</a-button
        >
        <a-button class="btn btn-outline-danger" @click="back"
          >Back</a-button
        >
      </a-button-group>
      </template>
    <div class="row pl-1 pr-1 pt-1 pb-1">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <!-- <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
            <strong class="">Supplier</strong>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <v-select
              ref="supplier_id"
              placeholder="Ketikan Nama Supplier"
              class="style-chooser"
              label="label"
              style="width: 100%;"
              v-model="input.supplier_id"
              :options="masterSupplier"
              :reduce="(tes) => tes.id"
              @input="nextFocus('keteranganinput')"
            />
          </div> -->
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
            <label class="">Order Penjualan</label>
          </div>
          <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
            <a-button-group>
              <a-button :class="input.sales_order_id !== '' && input.sales_order_id !== null ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'" @click="openModalSelectSales">{{ input.sales_order_id !== '' && input.sales_order_id !== null ? 'Active: ' + selectedDataSales !== null ? selectedDataSales.order_no : '' : '1 Penjualan' }}</a-button>
            <a-button class="btn btn-outline-danger" v-if="input.sales_order_id !== null && input.sales_order_id !== ''" @click="input.sales_order_id = null">
              <a-icon type="close"></a-icon>
            </a-button>
            </a-button-group>
            <!-- <v-select
              ref="employee_id"
              placeholder="Ketikan Nama Employee"
              class="style-chooser"
              label="label"
              style="width: 80%;"
              v-model="input.employee_id"
              :options="masterEmployee"
              :reduce="(tes) => tes.id"
              @input="nextFocus('keteranganinput')"
              /> -->
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
              <label class="">Tanggal Transaksi</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-date-picker
                style="width: 125px;"
                v-model="input.tgl"
                :allowClear="false"
                format="DD-MM-YYYY"
              />
            </div>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
            <label class="">Catatan Order</label>
          </div>
          <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-1">
            <!-- <a-textarea
              placeholder="Ketikan catatan tentang pesanan disini!"
              :auto-size="{ minRows: 2, maxRows: 6 }"
              v-model="input.catatan"
              /> -->
              <a-input
              style="width: 70%;"
              placeholder="Ketikan catatan tentang pesanan disini!"
              :readOnly="false"
              v-model="input.catatan"
            ></a-input>
          </div>
          <!-- <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-3">
            <label class="">Pilih Produk</label>
          </div> -->
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
            <v-select
              ref="selectedproduct"
              placeholder="Ketikan Nama Barang"
              class="style-chooser"
              label="label"
              style="width: 100%;"
              v-model="maskInput.selectedproduct"
              :options="masterBarang"
              :reduce="(tes) => tes.id"
              @input="selectProduk"
              />
          </div>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
            <a-input
              class=""
              placeholder="Satuan"
              v-model="maskInput.satuan"
              ref="satuaninput"
            ></a-input>
          </div>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
            <cleave
              ref="jumlah"
              :options="{ numeral: true }"
              class="ant-input mr-1"
              :raw="true"
              style="text-align: right;"
              v-model="maskInput.jumlah"
              placeholder="Jumlah"
              @keydown.native.enter="addDataToGrid"
            />
          </div>
          <!-- <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
            <cleave
              ref="harga"
              :options="{ numeral: true }"
              class="ant-input mr-1"
              :raw="true"
              style="text-align: right;"
              v-model="maskInput.harga"
              placeholder="Harga"
              @keydown.native.enter="addDataToGrid"
            />
          </div> -->
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
        <a-button-group>
            <a-button
              class="btn btn-outline-success"
              v-if="selectedData.length !== 0"
              @click="addDataToGrid"
              ><a-icon type="check"></a-icon> Save</a-button
            >
            <a-button
              class="btn btn-outline-primary"
              v-else
              @click="addDataToGrid"
              ><a-icon type="plus"></a-icon> Add</a-button
            >
            <a-button
              class="btn btn-outline-danger"
              v-if="selectedData.length !== 0"
              @click="removeFromGrid"
              ><a-icon type="delete"></a-icon> Remove</a-button
            >
            </a-button-group>
          </div>
        </div>
      </div>
      <!-- <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
        <table class="mt-3">
          <tr>
            <th>Action</th>
          </tr>
          <tr v-for="(d, i) in rowData" :key="i">
            <td>
        <a-button-group>
              <a-button href="javascript:void(0)" @click="selectProduk" class="btn btn-outline-warning">
                <a-icon class="" type="minus"></a-icon>1
              </a-button>
              <a-button href="javascript:void(0)" @click="remove()" class="btn btn-outline-danger">
                <a-icon class="" type="delete"></a-icon> {{ d.kode }}
              </a-button>
              <a-button href="javascript:void(0)" @click="selectProduk" class="btn btn-outline-success">
                <a-icon class="" type="plus"></a-icon>1
              </a-button>
            </a-button-group>
            </td>
            <td>
            </td>
          </tr>
        </table>
      </div> -->
      <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
        <ag-grid-vue
          style="height: 31vh;width: 100%;"
          :class="
            settings.theme === 'dark'
              ? 'ag-theme-balham-dark mt-2'
              : 'ag-theme-balham mt-2'
          "
          :rowClassRules="rowClassRules"
          :key="componentKey"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :pagination="false"
          :accentedSort="false"
          :sortingOrder="['asc', 'desc']"
          :rowData="rowData"
          @grid-ready="onGridReady"
          :singleClickEdit="false"
          :undoRedoCellEditing="true"
          :undoRedoCellEditingLimit="5"
          rowSelection="single"
          :frameworkComponents="frameworkComponents"
          @first-data-rendered="onFirstDataRendered"
          @selection-changed="onSelectionChanged"
          @cell-value-changed="onCellValueChanged"
        ></ag-grid-vue>
        <!-- <cleave class="ant-input mt-1 ml-1 pull-right" v-model="therealsubtotal" placeholder="Sub Total" :readOnly="true" style="width: 10%"></cleave> -->
        <!-- <strong class="mt-2 mr-1 pull-right">Sub Total:  {{ formatCurrency(therealsubtotal ?? 0) }}</strong> -->
      </div>
    </div>
    </a-card>
    <!-- <a-button
              ref="addBtn"
              class="ml-2 btn btn-outline-primary"
              @click="addNullData"
            >
              Tambah
            </a-button> -->
    <a-modal :visible="modalSales.visible"
      :dialogStyle="{ top: '10px' }"
      :footer="null"
      :width="900">
      <template slot="title">
        <strong>Pilih Data Order Penjualan</strong>
        <strong class="text-primary ml-3" v-if="input.sales_order_id !== '' && input.sales_order_id !== null">Active: {{ selectedDataSales !== null ? selectedDataSales.order_no : '' }}</strong>
      </template>
      <a-icon slot="closeIcon" type="close" @click="closedModalSales"></a-icon>
    <a-table
      class=""
      style="margin: -23px -23px;"
      :columns="modalSales.columns"
      :dataSource="modalSales.data"
      :pagination="{
        hideOnSinglePage: true,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total) => `Total ${total} items`,
        pageSizeOptions: ['10', '20', '30'],
      }"
      size="small"
      :loading="loading"
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (modalSales.searchInput = c)"
          :placeholder="`Search ${column.title}`"
          :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block"
          @change="
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          "
          @pressEnter="
            () => handleSearch(selectedKeys, confirm, column.dataIndex)
          "
        />
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          Search
        </a-button>
        <a-button
          size="small"
          style="width: 90px"
          @click="() => handleReset(clearFilters)"
        >
          Reset
        </a-button>
      </div>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      ></a-icon>
      <template slot="customRender" slot-scope="text, record, index, column">
        <span v-if="modalSales.searchText && searchedColumn === column.dataIndex">
          <template
            v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${modalSales.searchText})|(?=${modalSales.searchText})`, 'i'))"
          >
            <mark
              v-if="fragment.toLowerCase() === modalSales.searchText.toLowerCase()"
              :key="i"
              class="highlight"
              >{{ fragment }}</mark
            >
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>
          {{ text }}
        </template>
      </template>
      <span slot="action" slot-scope="text, record">
        <a-button :class="input.sales_order_id === '' || input.sales_order_id === null ? 'btn btn-outline-primary' : 'btn btn-outline-warning'" @click="selectingDataSales(record)">{{ input.sales_order_id === '' || input.sales_order_id === null ? 'Pilih' : 'Ganti'}}</a-button>
        <!-- <a class="text-warning" @click="sendToEdit(record)">
          <a-icon type="edit"></a-icon>
        </a>
        <a-divider type="vertical" />
        <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
          <a-icon type="delete"></a-icon>
        </a> -->
        <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
      </span>
      <span slot="tgl" slot-scope="text, record">
        {{ formatDate(record.tgl) }}
      </span>
      <template
      :expandIconColumnIndex="3" slot="expandedRowRender" slot-scope="record" style="margin: 1">
        <a-table
          :columns="modalSales.columnChildren"
          :dataSource="record.details"
          size="small"
          :pagination="{
            hideOnSinglePage: true,
            defaultPageSize: 10,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
          }"
        >
        <span slot="formatCurrency" slot-scope="text">
          {{ formatCurrency(text) }}
        </span>
        </a-table>
      </template>
    </a-table>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import numericInput from './component/numeric'
import selectInput from './component/select'
import dateInput from './component/date'
import tooltip from './component/tooltip'
import htmlrender from './component/htmlrender'
import removeData from './component/removeData'
import Cleave from 'vue-cleave-component'
import { Modal } from 'ant-design-vue'
// import vueCustomScrollbar from 'vue-custom-scrollbar'

import { mapState } from 'vuex'

export default {
  computed: mapState(['settings', 'user']),
  components: {
    // vueCustomScrollbar,
    cleave: Cleave,
  },
  created() {
    this.gridOptions = {}
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
    this.getMaster()
  },
  data() {
    return {
      action: 'create',
      title: 'Create Permintaan Material',
      therealsubtotal: '0',
      tgl1: moment(),
      editdata: JSON.parse(localStorage.getItem('projectatransaksimaterialrequest')),
      masterSupplier: [],
      masterEmployee: [],
      masterBarang: [],
      maskInput: {
        id: 0,
        selectedproduct: '',
        satuan: '',
        jumlah: '',
        harga: '',
      },
      input: {
        supplier_id: '',
        // employee_id: '',
        sales_order_id: '',
        catatan: '',
        tgl: moment(),
        details: [],
      },

      // property of modalsales
      selectedDataSales: null,
      modalSales: {
        visible: false,
        searchText: '',
        searchInput: null,
        searchedColumn: '',
        data: [],
        columns: [
          {
            title: 'Action',
            scopedSlots: { customRender: 'action' },
            width: 100,
          // fixed: 'left',
          },
          {
            title: 'No Order',
            dataIndex: 'order_no',
            width: 150,
            // fixed: 'left',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.order_no
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Tanggal',
            dataIndex: 'tgl',
            width: 100,
            // fixed: 'left',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.tgl.toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'N. Karyawan',
            dataIndex: 'nama_employee',
            width: 150,
            // fixed: 'left',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.nama_employee
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'N. Pelanggan',
            dataIndex: 'nama_customer',
            width: 150,
            // fixed: 'left',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.nama_customer
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Catatan',
            dataIndex: 'catatan',
            width: 150,
            // fixed: 'left',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.catatan
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
        ],
        columnChildren: [
          {
            title: 'Nama',
            dataIndex: 'product_name',
            ellipsis: true,
          // scopedSlots: {
          //   customRender: 'formatCurrency',
          // },
          },
          // {
          //   title: 'Harga',
          //   dataIndex: 'harga',
          //   ellipsis: true,
          //   align: 'right',
          //   scopedSlots: {
          //     customRender: 'formatCurrency',
          //   },
          //   sorter: (a, b) => a.harga - b.harga,
          // },
          {
            title: 'Jumlah',
            dataIndex: 'jumlah',
            ellipsis: true,
            align: 'right',
            scopedSlots: {
              customRender: 'formatCurrency',
            },
            sorter: (a, b) => a.jumlah - b.jumlah,
          },
          // {
          //   title: 'Diskon',
          //   dataIndex: 'diskon',
          //   ellipsis: true,
          //   align: 'right',
          //   scopedSlots: {
          //     customRender: 'formatCurrency',
          //   },
          //   sorter: (a, b) => a.diskon - b.diskon,
          // },
        ],
      },
      loading: false,
      // End of property sales modal

      // Property a-table
      componentKeyDatatable: 0,
      // End of property

      // ag-grid-property
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'text-danger': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return params.data.isPrimary !== undefined && params.data.isPrimary === 1
        },
        'font-weight-bold': (params) => {
          return params.data.isBold !== undefined
        },
      },
      changedData: [],
      frameworkComponents: {
        numericEditor: numericInput,
        dateEditor: dateInput,
        selectEditor: selectInput,
        customTooltip: tooltip,
        htmlRender: htmlrender,
        removeData: removeData,
      },
      selectedData: [],
      columnDefs: [],
      rowData: [],
      gridOptions: null,
      gridApi: null,
      componentKey: 0,
    }
  },
  methods: {
    moment,
    cancelSelectDataSales() {

    },
    // Modal select sales
    selectingDataSales(r) {
      this.selectedDataSales = r
      this.input.sales_order_id = r.id
      this.closedModalSales()
    },
    async openModalSelectSales() {
      var res = await lou.customUrlGet2('transaksi/purchase_order/sales_order')
      if (res) {
        this.modalSales.visible = true
        this.modalSales.data = res.data
      }
    },
    closedModalSales() {
      this.modalSales.visible = false
    },

    selectProduk() {
      // var exist = this.rowData.findIndex(x => x.id === this.maskInput.selectedproduct)
      // console.log('exist', exist)
      // var drowData = this.rowData[exist]
      var targetKerd = this.masterBarang.findIndex((x) => x.id === this.maskInput.selectedproduct)
      var dataTargetd = this.masterBarang[targetKerd]
      this.maskInput.satuan = dataTargetd.satuan
      this.maskInput.jumlah = 1
      this.maskInput.harga = dataTargetd.harga
      // if (exist < 0) {
      // var targetKer = this.masterBarang.findIndex((x) => x.id === this.maskInput.selectedproduct)
      // var dataTarget = this.masterBarang[targetKer]
      // var datafd = {
      //   ...dataTarget,
      //   jumlah: 1,
      //   totalharga: 0,
      // }
      // this.rowData.splice(this.rowData.length - 1, 0, datafd)
      // } else {
      // var drowData = this.rowData[exist]
      // var targetKerd = this.masterBarang.findIndex((x) => x.id === this.maskInput.selectedproduct)
      // var dataTargetd = this.masterBarang[targetKerd]
      // var datafdd = {
      //   ...dataTargetd,
      //   jumlah: parseInt(drowData.jumlah) + 1,
      //   totalharga: 0,
      // }
      // var cofrwdt = this.$g.clone(this.rowData)
      // var targetKerd1 = cofrwdt.findIndex((x) => x.id === this.maskInput.selectedproduct)
      // cofrwdt.splice(targetKerd1, 1)
      // cofrwdt.splice(targetKerd1, 0, datafdd)
      // this.rowData = this.$g.clone(cofrwdt)
      // }
      // this.maskInput.selectedproduct = ''
    },
    nextFocus(to) {
      // const input = this.$refs.rekening.$el.querySelector('input')
      // input.click()
      // this.$refs.rekening.$el.blur()
      // console.log('to', to)
      setTimeout(() => {
        this.$refs[to].$el.focus()
      }, 200)
    },
    removeFromGrid() {
      var indexTarget = this.rowData.findIndex(
        (x) => x.uuid === this.selectedData[0].uuid,
      )
      this.rowData.splice(indexTarget, 1)
    },
    bataledit() {
      this.selectedData = []
      this.maskInput = {
        id: 0,
        selectedproduct: '',
        satuan: '',
        jumlah: '',
        harga: '',
      }
    },
    addDataToGrid() {
      var exist = this.rowData.findIndex(x => x.id === this.maskInput.selectedproduct)
      // console.log('exist', exist)
      if (exist < 0) {
        var targetKer = this.masterBarang.findIndex((x) => x.id === this.maskInput.selectedproduct)
        var dataTarget = this.masterBarang[targetKer]
        var datafd = {
          ...dataTarget,
          satuan: this.maskInput.satuan === '' ? dataTarget.satuan : this.maskInput.satuan,
          jumlah: this.maskInput.jumlah === '' ? 1 : this.maskInput.jumlah,
          harga: this.maskInput.harga === '' ? dataTarget.harga : this.maskInput.harga,
          totalharga: 0,
        }
        this.rowData.splice(this.rowData.length - 1, 0, datafd)
      } else {
        // var drowData = this.rowData[exist]
        var targetKerd = this.masterBarang.findIndex((x) => x.id === this.maskInput.selectedproduct)
        var dataTargetd = this.masterBarang[targetKerd]
        var datafdd = {
          ...dataTargetd,
          satuan: this.maskInput.satuan === '' ? dataTarget.satuan : this.maskInput.satuan,
          jumlah: this.maskInput.jumlah === '' ? 1 : this.maskInput.jumlah,
          harga: this.maskInput.harga === '' ? dataTarget.harga : this.maskInput.harga,
          totalharga: 0,
        }
        var cofrwdt = this.$g.clone(this.rowData)
        var targetKerd1 = cofrwdt.findIndex((x) => x.id === this.maskInput.selectedproduct)
        cofrwdt.splice(targetKerd1, 1)
        cofrwdt.splice(targetKerd1, 0, datafdd)
        this.rowData = this.$g.clone(cofrwdt)
      }
      // if (this.selectedData.length === 0) {
      //   var targetKer = this.masterRekening.findIndex((x) => x.kode === this.maskInput.rekening)
      //   var dataTarget = this.masterRekening[targetKer]
      //   var datafd = {
      //     uuid: this.$uuid.v4(),
      //     no: this.maskInput.no,
      //     id: dataTarget.id,
      //     rekening: dataTarget.kode,
      //     nama_rekening: dataTarget.keterangan,
      //     keterangan: this.maskInput.keterangan,
      //     debet: this.maskInput.debet !== '' ? this.maskInput.debet : '0',
      //     kredit: this.maskInput.kredit !== '' ? this.maskInput.kredit : '0',
      //   }
      //   this.rowData.splice(this.rowData.length - 1, 0, datafd)
      //   this.hitHitungTotal()
      // } else {
      //   var target = this.rowData.findIndex((x) => x.uuid === this.selectedData[0].uuid)
      //   if (target !== -1) {
      //     var targetrek = this.masterRekening.findIndex(
      //       (x) => x.kode === this.maskInput.rekening,
      //     )
      //     var dataTargetRek = this.masterRekening[targetrek]
      //     var data = this.rowData[target]
      //     data.id = dataTargetRek.id
      //     data.rekening = dataTargetRek.kode
      //     data.nama_rekening = dataTargetRek.keterangan
      //     data.keterangan = this.maskInput.keterangan
      //     data.debet = this.maskInput.debet !== '' ? this.maskInput.debet : '0'
      //     data.kredit = this.maskInput.kredit !== '' ? this.maskInput.kredit : '0'
      //     this.selectedData = []
      //     this.componentKey += 1
      //     this.hitHitungTotal()
      //   }
      // }
      this.selectedData = []
      this.maskInput = {
        id: 0,
        selectedproduct: '',
        satuan: '',
        jumlah: '',
        harga: '',
      }
      this.hitungSubTotal()
      // const input = this.$refs.rekening.$el.querySelector('input')
      // input.focus()
    },
    hitungSubTotal() {
      var vt = 0
      this.rowData.forEach((element) => {
        vt += parseInt(element.jumlah) * parseInt(element.harga)
      })
      this.therealsubtotal = vt
      console.log('this.therealsubtotal', this.therealsubtotal)
    },
    hitHitungTotal() {
      var target = this.rowData.findIndex(
        (x) => x.uuid === 'totaldarisemuanya',
      )
      // console.log('this.rowData.length', this.rowData.length)
      // console.log('this.rowData[this.rowData.length - 1]', this.rowData[this.rowData.length - 1])
      // this.rowData.splice(this.rowData.length - 1, 1)
      var totaldebet = 0
      var totalkredit = 0
      this.rowData.forEach((element) => {
        if (element.uuid !== 'totaldarisemuanya') {
          totaldebet += parseFloat(element.debet)
          totalkredit += parseFloat(element.kredit)
        }
        // console.log('element.debet', element.debet)
        // console.log('element.kredit', element.kredit)
      })
      this.rowData[target].debet = totaldebet
      this.rowData[target].kredit = totalkredit
      // this.rowData.push(
      //   {
      //     uuid: 'totaldarisemuanya',
      //     rekening: '',
      //     namarek: '',
      //     keterangan: 'Total',
      //     debet: totaldebet,
      //     kredit: totalkredit,
      //   },
      // )
    },
    async getMaster() {
      this.columnDefs = [
        {
          headerName: '#',
          width: 50,
          editable: false,
          resizable: false,
          sortable: false,
          cellRenderer: 'removeData',
        },
        {
          headerName: 'Kode',
          field: 'kode',
          width: 80,
          editable: false,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Nama',
          field: 'nama',
          editable: false,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Satuan',
          field: 'satuan',
          editable: false,
          resizable: true,
          width: 120,
          sortable: true,
        },
        {
          headerName: 'Jumlah',
          field: 'jumlah',
          width: 80,
          editable: true,
          resizable: true,
          cellEditor: 'numericEditor',
          sortable: true,
          type: 'rightAligned',
          // valueGetter: params => {
          //   // console.log('params.data.jumlah', params.data.jumlah)
          //   return params.data.jumlah
          // },
        },
        // {
        //   headerName: 'Harga',
        //   field: 'harga',
        //   editable: false,
        //   resizable: true,
        //   sortable: true,
        //   width: 150,
        //   type: 'rightAligned',
        //   valueFormatter: (params) => {
        //     // console.log('params', params)
        //     var ret = params.value === null ? 'NaN' : lou.curency(params.value)
        //     return ret === 'NaN' ? '' : ret
        //   },
        // },
        // {
        //   headerName: 'Total Harga',
        //   isBold: 'true',
        //   valueGetter: params => {
        //     // console.log('params.data.jumlah', params.data.jumlah)
        //     var total = parseInt(params.data.harga) * parseInt(params.data.jumlah)
        //     return total
        //   },
        //   editable: false,
        //   resizable: true,
        //   sortable: true,
        //   width: 150,
        //   type: 'rightAligned',
        //   valueFormatter: (params) => {
        //     // console.log('params', params)
        //     var ret = params.value === null ? 'NaN' : lou.curency(params.value)
        //     return ret === 'NaN' ? '' : ret
        //   },
        // },
      ]
      // this.componentKey += 1
      var resmasterBarang = await lou.customUrlGet2('transaksi/purchase_order/bahan_baku')
      // var resmasterSupplier = await lou.customUrlGet2('transaksi/production/material_request/supplier')
      var res = await lou.customUrlGet2('transaksi/purchase_order/sales_order')
      if (res) {
        // this.modalSales.visible = true
        this.modalSales.data = res.data
      }
      // if (resmasterSupplier) {
      //   this.masterSupplier = []
      //   resmasterSupplier.data.forEach((element) => {
      //     var nobj = {
      //       ...element,
      //       label: element.nama_perusahaan + ' - ' + element.nama_kontak,
      //     }
      //     this.masterSupplier.push(nobj)
      //   })
      //   // console.log('this.masterRekening', this.masterRekening)
      // }
      if (resmasterBarang) {
        this.masterBarang = []
        resmasterBarang.data.forEach((element) => {
          var nobj = {
            ...element,
            label: element.kode + ' - ' + element.nama,
          }
          this.masterBarang.push(nobj)
        })
        // console.log('this.masterRekening', this.masterRekening)
      }
      if (this.editdata !== null && this.editdata.id !== null && this.editdata.id !== '') {
        this.action = 'update'
        this.title = 'Update Permintaan Material'
        this.disabledAllInputExcludeNoRekening = true
        this.getEditData()
      } else {
        this.action = 'create'
        this.title = 'Create Permintaan Material'
        // this.tempInput.f2digitreg = this.user.user_branch
      }
      // this.rowData.push({
      //   uuid: 'totaldarisemuanya',
      //   rekening: '',
      //   namarek: '',
      //   keterangan: 'Total',
      //   debet: 0,
      //   kredit: 0,
      // })
    },
    async handleSubmit() {
      // console.log('this.rowData', this.rowData)
      if (this.rowData.length >= 1) {
        // Modal.confirm({
        //   title: 'Apakah anda yakin data sudah benar?',
        //   content: 'Data tidak dapat di edit ataupun di hapus nanti!',
        //   okText: 'Yes',
        //   okType: 'danger',
        //   cancelText: 'No',
        //   onOk: async () => {
        var fd = this.$g.clone(this.input)
        fd.tgl = this.input.tgl.format('YYYY-MM-DD')
        if (fd.redoInput !== undefined) { delete fd.redoInput }
        if (fd.redoInputdisable !== undefined) { delete fd.redoInputdisable }
        if (fd.undoInput !== undefined) { delete fd.undoInput }
        if (fd.undoInputdisable !== undefined) { delete fd.undoInputdisable }
        fd.details = this.$g.clone(this.rowData)
        fd.sales_order_no = this.selectedDataSales === null ? null : this.selectedDataSales.order_no
        fd.details.forEach((element) => {
          if (element.label !== undefined) { delete element.label }
          element.product_id = element.id
          element.jumlah = parseInt(element.jumlah)
          // element.harga = parseInt(element.harga) * parseInt(element.jumlah)
          delete element.id
          if (this.action === 'create') {
            delete element.nama
            delete element.kode
            delete element.satuan
          }
          delete element.totalharga
        })
        fd.sales_order_id = fd.sales_order_id === '' ? null : fd.sales_order_id
        var res = null
        if (this.action === 'create') {
          res = await lou.customUrlPost2('transaksi/production/material_request', fd)
        } else {
          res = await lou.customUrlPut2('transaksi/production/material_request', fd)
        }
        if (res) {
          this.resetForm()
          // this.getAllData()
        }
      //   },
      //   onCancel: () => {
      //     // console.log('Cancel')
      //     return false
      //   },
      // })
      } else {
        lou.shownotif('Peringatan!', 'Tidak ada data yang diinput.', 'error')
      }
    },

    resetForm(back = false) {
      this.editdata = {}
      this.action = 'create'
      this.selectedData = []
      this.maskInput = {
        id: 0,
        selectedproduct: '',
        satuan: '',
        jumlah: '',
        harga: '',
      }
      this.input = {
        supplier_id: '',
        sales_order_id: '',
        catatan: '',
        tgl: moment(),
        details: [],
      }
      this.rowData = []
      if (back) {
        localStorage.removeItem('projectatransaksimaterialrequest')
        this.$parent.active = 0
        // this.$router.push({ path: '/projecta/transaksi/orderpembelian' })
      }
    },
    // ag-grid function
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onCellValueChanged(params) {
      // var undoSize = params.api.getCurrentUndoSize()
      // this.input.undoInput = undoSize
      // this.input.undoInputdisable = undoSize < 1
      // var redoSize = params.api.getCurrentRedoSize()
      // this.input.redoInput = redoSize
      // this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
      this.hitungSubTotal()
      // console.log('this.mode', this.mode)
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      if (selectedRows[0].uuid !== 'totaldarisemuanya') {
        this.selectedData = selectedRows
        this.maskInput.selectedproduct = selectedRows[0].id
        this.maskInput.id = selectedRows[0].id
        this.maskInput.satuan = this.selectedData[0].satuan
        this.maskInput.jumlah = this.selectedData[0].jumlah
        this.maskInput.harga = this.selectedData[0].harga
      } else {
        lou.shownotif('Total', 'Total akan terupdate secara otomatis.')
      }
      // console.log('selectedRows', selectedRows)
    },
    onFirstDataRendered() {
      // this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },

    getEditData() {
      this.action = 'update'
      this.selectedData = []
      var target = this.modalSales.data.findIndex(z => z.id === this.editdata.sales_order_id)
      // console.log('target', target)
      if (target >= 0) {
        this.selectedDataSales = this.modalSales.data[target]
      }
      // console.log('this.selectedDataSales', this.selectedDataSales)
      this.maskInput = {
        id: 0,
        selectedproduct: '',
      }
      this.input = {
        id: this.editdata.id,
        supplier_id: this.editdata.supplier_id,
        sales_order_id: this.editdata.sales_order_id,
        catatan: this.editdata.catatan,
        tgl: moment(),
        details: this.editdata.details,
        order_no: this.editdata.order_no,
      }
      this.rowData = []
      var tempdata = this.$g.clone(this.editdata.details)
      tempdata.forEach(element => {
        // var idbarang = this.masterBarang.findIndex(x => x.id === element.product_id)
        // var dbarang = this.masterBarang[idbarang]
        var dfasdf = {
          ...element,
          // nama: element.product_name,
          // kode: dbarang.kode,
          // satuan: dbarang.satuan,
          // harga: dbarang.harga,
          id: element.product_id,
        }
        this.rowData.push(dfasdf)
      })
      // this.hitHitungTotal()
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2('transaksi/production/material_request/' + deldata)
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    dateFormatter(params) {
      var ret =
        params.value === null
          ? ''
          : moment(params.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      // console.log('dateFormatter', ret)
      return ret !== 'Invalid date' ? ret : params.value
    },
    currencyFormatter(params) {
      var ret =
        params.value === null
          ? 'NaN'
          : lou.curency(parseFloat(params.value).toFixed(0))
      return ret === 'NaN' ? '' : ret
    },
    formatTanggal(v) {
      // console.log('v', v)
      // console.log('ntgl', ntgl)
      return moment(v.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    getTotalDetails(what, data) {
      var total = 0

      data.forEach(element => {
        total += element[what]
      })

      return total
    },
    back() {
      this.$parent.active = 0
      // this.$router.go(-1)
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style lang="scss" scoped></style>
